import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219'),
	() => import('./nodes/220'),
	() => import('./nodes/221'),
	() => import('./nodes/222'),
	() => import('./nodes/223'),
	() => import('./nodes/224'),
	() => import('./nodes/225'),
	() => import('./nodes/226'),
	() => import('./nodes/227'),
	() => import('./nodes/228'),
	() => import('./nodes/229'),
	() => import('./nodes/230'),
	() => import('./nodes/231'),
	() => import('./nodes/232'),
	() => import('./nodes/233')
];

export const server_loads = [0,7,8,4,26,22,23];

export const dictionary = {
		"/(marketing)": [169,[16]],
		"/(marketing)/about": [170,[16,17]],
		"/(authed)/account": [89,[7]],
		"/(authed)/actions": [~91,[7,8]],
		"/(authed)/actions/board": [93,[7,8]],
		"/(authed)/actions/table": [94,[7,8]],
		"/(authed)/actions/[view]": [92,[7,8]],
		"/(authed)/action/[itemId]": [~90,[7]],
		"/(admin)/admin": [34,[2]],
		"/(admin)/admin/abundant/add-update": [~35,[2]],
		"/(admin)/admin/abundant/duplicate-risk-type": [~36,[2]],
		"/(admin)/admin/abundant/evict-file-cache": [~37,[2]],
		"/(admin)/admin/abundant/migrate-db": [~38,[2]],
		"/(admin)/admin/abundant/open-api": [39,[2]],
		"/(admin)/admin/abundant/queries-sg": [~40,[2]],
		"/(admin)/admin/abundant/reset-db": [~41,[2]],
		"/(admin)/admin/abundant/sync-indexes-to-db": [~42,[2]],
		"/(admin)/admin/abundant/sync-indexes-to-disk": [~43,[2]],
		"/(admin)/admin/abundant/sync-validation-to-db": [~44,[2]],
		"/(admin)/admin/abundant/sync-validation-to-disk": [~45,[2]],
		"/(admin)/admin/categories/add": [~46,[2]],
		"/(admin)/admin/charts/bar": [~47,[2]],
		"/(admin)/admin/dev-ops/analytics.calendar-month": [~48,[2]],
		"/(admin)/admin/dev-ops/analytics.items-per-month-full-load": [~49,[2]],
		"/(admin)/admin/dev-ops/analytics.items-per-month-incremental-load": [~50,[2]],
		"/(admin)/admin/dev-ops/find-unused-files": [~51,[2]],
		"/(admin)/admin/dev-ops/run-pipelines": [~52,[2]],
		"/(admin)/admin/misc/add-analytics-board": [~53,[2]],
		"/(admin)/admin/misc/create-thumbnails-by-id": [~54,[2]],
		"/(admin)/admin/misc/customer-scripts": [~55,[2]],
		"/(admin)/admin/misc/get-asset-info": [~56,[2]],
		"/(admin)/admin/misc/sendgrid-delete-contacts": [~57,[2]],
		"/(admin)/admin/risk-data/copy-risk-model-to-prod": [~58,[2]],
		"/(admin)/admin/risk-data/copy-risk-type": [~59,[2]],
		"/(admin)/admin/test-email": [60,[2]],
		"/(admin)/admin/users/assign-permissions": [~61,[2]],
		"/(admin)/admin/users/delete": [~62,[2]],
		"/(admin)/admin/users/end-session": [~63,[2]],
		"/(admin)/admin/users/seed": [~64,[2]],
		"/(admin)/admin/users/set-user-tasks": [~65,[2]],
		"/(admin)/admin/users/toggle-21risk-admin": [~66,[2]],
		"/(app)/analytics/[_id]": [69,[4]],
		"/(authed)/automations/actions": [~95,[7,9]],
		"/(authed)/automations/actions/new": [~97,[7,9]],
		"/(authed)/automations/actions/[_id=objectId]": [~96,[7,9]],
		"/(authed)/automations/models/[riskModelId=objectId]": [~98,[7,9]],
		"/(authed)/automations/models/[riskModelId=objectId]/new": [~100,[7,9]],
		"/(authed)/automations/models/[riskModelId=objectId]/[_id=objectId]": [~99,[7,9]],
		"/(authed)/a/[reportId]/[[page]]": [~88,[7]],
		"/(marketing)/blog": [171,[16,18]],
		"/(marketing)/blog/[slug]": [172,[16,18]],
		"/(app)/board": [70,[4]],
		"/(app)/board/[boardId]": [71,[4,5]],
		"/(app)/board/[boardId]/edit": [~72,[4,5]],
		"/(marketing)/changelog": [173,[16]],
		"/(marketing)/changelog/[slug]": [174,[16]],
		"/(marketing)/checklists": [~175,[16]],
		"/(marketing)/checklists/success": [179,[16]],
		"/(marketing)/checklists/view": [180,[16]],
		"/(marketing)/checklists/[_id=objectId]": [~176,[16]],
		"/(marketing)/checklists/[_id=objectId]/print": [~177,[16]],
		"/(marketing)/checklists/[_id=objectId]/request": [~178,[16]],
		"/(docs)/compliance": [163,[15]],
		"/(docs)/compliance/[...rest]": [~164,[15]],
		"/(marketing)/contact": [181,[16]],
		"/(marketing)/contact/sales": [~182,[16]],
		"/(marketing)/contact/sales/success": [183,[16]],
		"/(authed)/corrective-actions": [102,[7]],
		"/(authed)/corrective-action/[_id]": [101,[7]],
		"/(marketing)/customers": [184,[16,19]],
		"/(marketing)/customers/BHJ": [185,[16,19]],
		"/(marketing)/customers/[slug]": [186,[16,19]],
		"/(authed)/dashboard": [~103,[7]],
		"/(authed)/dashboard/empty": [106,[7]],
		"/(authed)/dashboard/[riskTypeId=objectId]": [~105,[7]],
		"/(authed)/dashboard/[...rest]": [104,[7]],
		"/(authed)/debug": [107,[7,10]],
		"/(authed)/debug/config/print-values": [108,[7,10]],
		"/(authed)/debug/errors/error-example-10": [111,[7,10]],
		"/(authed)/debug/errors/error-example-11": [112,[7,10]],
		"/(authed)/debug/errors/error-example-12": [113,[7,10]],
		"/(authed)/debug/errors/error-example-1": [~110,[7,10]],
		"/(authed)/debug/errors/error-example-2": [~114,[7,10]],
		"/(authed)/debug/errors/error-example-30": [~116,[7,10]],
		"/(authed)/debug/errors/error-example-31": [~117,[7,10]],
		"/(authed)/debug/errors/error-example-32": [~118,[7,10]],
		"/(authed)/debug/errors/error-example-3": [115,[7,10]],
		"/(authed)/debug/errors/error-example-5": [~119,[7,10]],
		"/(authed)/debug/errors/[...0]intro": [109,[7,10]],
		"/(authed)/debug/forms/[...0]intro": [120,[7,10]],
		"/(docs)/docs": [165,[15]],
		"/(docs)/docs/concepts": [167,[15]],
		"/(docs)/docs/concepts/[...rest]": [168,[15]],
		"/(docs)/docs/[...rest]": [~166,[15]],
		"/(reset)/emails/preview/[templateId]/[sendgridPayload]": [~218,[26]],
		"/(marketing)/experts": [187,[16,20]],
		"/(marketing)/experts/[slug]": [~188,[16,20,21]],
		"/(marketing)/experts/[slug]/success": [189,[16,20,21]],
		"/(marketing)/features/actions": [190,[16]],
		"/(marketing)/features/audits": [191,[16]],
		"/(unauthed)/feedback/emails/thank-you": [225,[28,29]],
		"/(unauthed)/feedback/emails/[emailId]": [~224,[28,29]],
		"/(authed)/file-upload": [~121,[7]],
		"/(app)/folder/[_id]": [73,[4]],
		"/(app)/getting-started": [~74,[4]],
		"/(marketing)/help": [~192,[16,22]],
		"/(marketing)/help/success": [193,[16,22]],
		"/(authed)/insurance/get-started": [123,[7]],
		"/(authed)/insurance/[[page]]": [122,[7]],
		"/(authed)/landing-page": [124,[7]],
		"/(marketing)/legal": [194,[16]],
		"/(marketing)/legal/[slug]": [195,[16]],
		"/(unauthed)/library-public": [226,[28]],
		"/(authed)/library": [~125,[7]],
		"/(authed)/library/edit/[_id=objectId]": [~127,[7]],
		"/(authed)/library/new": [~128,[7]],
		"/(authed)/library/[_id=objectId]": [~126,[7]],
		"/(unauthed-prerender)/login": [~229,[30,31]],
		"/(unauthed-prerender)/login/check": [~230,[30,31]],
		"/(authed)/manage-data": [~129,[7,11]],
		"/(authed)/manage-data/locations": [~130,[7,11,12]],
		"/(authed)/manage-data/locations/import": [~132,[7,11,12]],
		"/(authed)/manage-data/locations/table": [~133,[7,11,12]],
		"/(authed)/manage-data/locations/[siteId]": [~131,[7,11,12]],
		"/(authed)/manage-data/tags": [~134,[7,11]],
		"/(authed)/manage-data/tags/[tagId]": [~135,[7,11]],
		"/(authed)/members": [~136,[7]],
		"/(authed)/members/new": [~138,[7]],
		"/(authed)/members/[user_id]": [~137,[7]],
		"/(admin)/mgmt": [67,[3]],
		"/(admin)/mgmt/analytics/overview": [~68,[3]],
		"/(unauthed)/misc/debug": [227,[28]],
		"/(unauthed)/misc/files-list": [~228,[28]],
		"/(authed)/models": [139,[7]],
		"/(marketing)/newsletter/check": [196,[16]],
		"/(marketing)/newsletter/subscribe": [~197,[16]],
		"/(marketing)/newsletter/success": [198,[16]],
		"/(marketing)/newsletter/verify": [~199,[16]],
		"/(app)/new/board": [~75,[4]],
		"/(app)/new/folder": [~76,[4]],
		"/(reset)/onboarding": [219,[26,27]],
		"/(reset)/onboarding/1": [220,[26,27]],
		"/(reset)/onboarding/2": [~221,[26,27]],
		"/(reset)/onboarding/3": [~222,[26,27]],
		"/(reset)/onboarding/4": [223,[26,27]],
		"/(marketing)/partners": [~200,[16]],
		"/(marketing)/partners/success": [201,[16]],
		"/(marketing)/pricing": [202,[16]],
		"/(authed)/profile": [~140,[7,13]],
		"/(authed)/profile/account": [~141,[7,13]],
		"/(authed)/profile/account/balance-changes": [~142,[7,13]],
		"/(authed)/profile/account/roles/[roleId]": [~143,[7,13]],
		"/(authed)/profile/billing": [144,[7,13]],
		"/(authed)/profile/integrations": [145,[7,13]],
		"/(authed)/profile/notifications": [146,[7,13]],
		"/(authed)/reports/a": [155,[7]],
		"/(authed)/reports/request-audit": [~156,[7]],
		"/(authed)/reports/[[view]]": [~154,[7,14]],
		"/(authed)/report/new": [~150,[7]],
		"/(authed)/report/new/[riskType=objectId]": [~151,[7]],
		"/(authed)/report/new/[riskType=objectId]/[site=objectId]": [~152,[7]],
		"/(authed)/report/start/[_id=objectId]": [~153,[7]],
		"/(authed)/report/[_id=objectId]": [~147,[7]],
		"/(authed)/report/[_id=objectId]/print": [~148,[7]],
		"/(authed)/report/[_id=objectId]/qr-code": [~149,[7]],
		"/(authed)/risk-reports": [157,[7]],
		"/(authed)/risk-reports/[_id]": [158,[7]],
		"/(marketing)/roadmap": [203,[16]],
		"/(app)/settings/account": [~77,[4,6]],
		"/(app)/settings/account/roles/[roleId]": [~78,[4,6]],
		"/(app)/settings/profile": [~79,[4,6]],
		"/(app)/settings/sites": [~80,[4,6]],
		"/(app)/settings/sites/import": [~82,[4,6]],
		"/(app)/settings/sites/[siteId=objectId]": [~81,[4,6]],
		"/(app)/settings/tags": [~83,[4,6]],
		"/(app)/settings/tags/[tagId=objectId]": [~84,[4,6]],
		"/(app)/settings/users": [~85,[4,6]],
		"/(app)/settings/users/new": [~87,[4,6]],
		"/(app)/settings/users/[user_id]": [~86,[4,6]],
		"/(authed)/sign-in": [159,[7]],
		"/(unauthed-prerender)/signup": [~231,[30,32]],
		"/(unauthed-prerender)/signup/check": [~232,[30,32]],
		"/(unauthed-prerender)/signup/complete": [~233,[30,32]],
		"/(marketing)/solutions/compliance": [204,[16]],
		"/(marketing)/solutions/insurance-values": [205,[16]],
		"/(marketing)/solutions/natcat": [206,[16]],
		"/(marketing)/solutions/natcat/[hazard]": [~207,[16]],
		"/(marketing)/solutions/property-insurance": [~208,[16]],
		"/(marketing)/solutions/property-insurance/roi-breakdown": [~209,[16]],
		"/(marketing)/solutions/property-insurance/roi-breakdown/success": [210,[16]],
		"/(marketing)/solutions/property-insurance/roi-calculator": [~211,[16]],
		"/(authed)/support": [160,[7]],
		"/(authed)/testError-load": [162,[7]],
		"/(authed)/testError": [161,[7]],
		"/(marketing)/updates": [212,[16,23]],
		"/(marketing)/updates/[slug]": [213,[16,23]],
		"/(marketing)/whitepapers": [214,[16,24]],
		"/(marketing)/whitepapers/success": [216,[16,24]],
		"/(marketing)/whitepapers/view/[uuid]": [217,[16,24]],
		"/(marketing)/whitepapers/[slug]": [~215,[16,24,25]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';